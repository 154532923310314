export const SUCCESS_CODE = 0; //成功状态码
export const YSC = false; //预生产

// const DEV_URL = "http://192.168.101.147:18071"; //张黎明
const DEV_URL = "http://192.168.100.4:18071"; // 测试环境？
// const DEV_URL = "http://47.99.186.69:30052"; // 开发环境
// const DEV_URL = "https://vip.fangmao.com/vip2";
const PROD_URL = "https://vip.fangmao.com/vip2";

const BASE_URL =
  location.hostname === "vip.fangmao.com" ||
  location.hostname === "vip.fangyibao.cn" ||
  YSC
    ? PROD_URL
    : DEV_URL;

export { BASE_URL };
// 导出订单
export const EXPORT_ORDER_URL =
  BASE_URL +
  "/api/v1/platform/open/auotation/activity/appointment/manage/order/export";

export const UPLOAD_IMG_URL = BASE_URL + "/api/v1/upload/img";

export const ERROR_MSG = "请求出错！";

export const LOGIN_URL = "https://svip.fangmao.com/";

// 高德地图key 【用于搜索等】
export const AMAP_KEY = "faca7f10327c4ada997ec60a588e6ffb";

// 高德地图 UI组件 key 【绘制地图】
export const AMAP_UI_KEY = "9c2563745618d38053dad78bfb27dab6";

// OSS 地址
export const OSS_URL = "https://cdnoss.fangmao.com";

// 二维码生成图片地址
export const QRCODE_GENERATE_URL = `https://fenxiao2.fangmao.com/fxb/public/qrcode?content=`;

// 默认头像地址
export const DEFAULT_AVATAR_URL =
  "https://cdnoss.fangmao.com/files/2020/08/20/d4ee1329-9f1b-4fb8-8f8d-62bd1922d301.png";

// 环境
export const ENV_VERSION = location.host.includes("fangmao.com")
  ? "release"
  : location.host.includes("101.37.129.89")
  ? "test"
  : "develop";

// pcn H5 webview(iframe) 基础地址
export const PCN_H5_URL_MAP = {
  release: "https://dealdata.fangmao.com/fmH5/#",
  test: "http://101.37.129.89:30015/#",
  // develop: "http://192.168.1.18:8200/#",
  develop: "http://192.168.1.18:8200/#"
};
export const PCN_H5_URL = PCN_H5_URL_MAP[ENV_VERSION];

export const enumeration = {
  propertyTypesList: {
    1: "住宅",
    2: "公寓",
    3: "别墅",
    4: "写字楼",
    5: "商铺"
  },
  saleStatus: {
    1: "待售",
    2: "即将开盘",
    3: "在售",
    4: "下期待开",
    5: "已售罄"
  },
  propertyTypes: [
    { value: 1, label: "住宅" },
    { value: 2, label: "公寓" },
    { value: 3, label: "别墅" },
    { value: 4, label: "写字楼" },
    { value: 5, label: "商铺" }
  ],
  saleStatus2: [
    { value: 0, label: "待定" },
    { value: 1, label: "待售" },
    { value: 2, label: "即将开盘" },
    { value: 3, label: "在售" },
    { value: 4, label: "下期待开" },
    { value: 5, label: "已售罄" }
  ],
  openDateType: [
    { value: 4, label: "最近开盘" },
    { value: 1, label: "最近7天" },
    { value: 2, label: "最近15天" },
    { value: 3, label: "最近30天" }
  ],
  estateHouseStatus: {
    1: "期房",
    2: "现房",
    3: "尾房"
  },
  openingCarStateObj: {
    AVAILABLE_SALE: {
      name: "可售",
      color: "#377d22",
      bgColor: "#e3fee1"
    },
    SUBSCRIPTION: {
      name: "认购",
      color: "#e49c54",
      bgColor: "#f9e9db"
    },
    SOLD: {
      name: "成交",
      color: "#eb3323",
      bgColor: "#facdc9"
    },
    LOCK: {
      name: "锁定",
      color: "#26475a",
      bgColor: "#c9d2d6"
    },
    UN_SHELVE: {
      name: "下架",
      color: "#999999",
      bgColor: "#f2f2f2"
    }
  },
  terminalSourceMaps: {
    1: "小程序",
    4: "房猫CMS·WEB",
    7: "IOS",
    8: "Android"
  }, // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
  customerSourceMaps: {
    1: "房猫找房小程序",
    2: "房猫找房",
    3: "房猫P+",
    4: "房猫CMS",
    5: "分销平台",
    6: "房猫PCN"
  },
  authSlaveTypeMaps: {
    1: {
      label: "地产达人",
      type: "success"
    },
    2: {
      label: "置业顾问",
      type: ""
    },
    3: {
      label: "PCN经纪",
      type: "warning"
    },
    4: {
      label: "媒体号",
      type: ""
    },
    5: {
      label: "新房助理",
      type: "warning"
    },
    6: {
      label: "驻场客服",
      type: ""
    },
    7: {
      label: "项目经理",
      type: "cyan"
    },
    8: {
      label: "销秘",
      type: "warning"
    },
    9: {
      label: "销售经理",
      type: ""
    },
    10: {
      label: "财务人员",
      type: "cyan"
    }
  } // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪 ,
};
